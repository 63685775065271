import { render, staticRenderFns } from "./SkillLevelUpPricesTable.vue?vue&type=template&id=42930251&scoped=true&"
import script from "./SkillLevelUpPricesTable.vue?vue&type=script&lang=js&"
export * from "./SkillLevelUpPricesTable.vue?vue&type=script&lang=js&"
import style0 from "./SkillLevelUpPricesTable.vue?vue&type=style&index=0&id=42930251&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "42930251",
  null
  
)

export default component.exports