<template>
  <div>
    <md-table md-card table-header-color="blue" :value="skillLevelUpPrices">
      <md-table-toolbar>
        <div class="md-toolbar-section-start">
          <h1 class="md-title">Стоимость прокачки скиллов</h1>
        </div>
      </md-table-toolbar>

      <md-table-empty-state md-label="Список пуст" />

      <md-table-row slot="md-table-row" slot-scope="{ item }">
        <md-table-cell md-label="Уровень">
          {{ item.level }}
        </md-table-cell>

        <md-table-cell md-label="Стоимость в кристаллах">
          {{ item.crystal }}
        </md-table-cell>

        <md-table-cell md-label="Стоимость в книгах">
          {{ item.book }}
        </md-table-cell>

        <md-table-cell>
          <md-button
            class="md-just-icon md-simple md-primary"
            @click="openItemInModal(item)"
          >
            <md-icon>edit</md-icon>
            <md-tooltip md-direction="left">Изменить</md-tooltip>
          </md-button>
        </md-table-cell>
      </md-table-row>
    </md-table>

    <SkillLevelUpPricesModal
      v-if="showModal && !!selectedItem"
      :item="selectedItem"
      @close="closeModal"
    />
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";

export default {
  components: {
    SkillLevelUpPricesModal: () =>
      import("@/components/Modals/SkillLevelUpPricesModal"),
  },

  data() {
    return {
      selectedItem: null,
      showModal: false,
    };
  },

  computed: {
    ...mapState("levelUpPrices", ["skillLevelUpPrices"]),
  },

  async mounted() {
    this.showLoader(true);
    await this.getSkillLevelUpPrices();
    this.showLoader(false);
  },

  methods: {
    ...mapActions("levelUpPrices", ["getSkillLevelUpPrices"]),
    ...mapMutations({
      showLoader: "SET_SHOW_LOADER",
    }),

    async closeModal(needUpdate) {
      this.showModal = false;
      this.selectedItem = null;

      if (needUpdate == true) {
        this.showLoader(true);
        await this.getSkillLevelUpPrices();
        this.showLoader(false);
      }
    },

    openItemInModal(item) {
      this.selectedItem = item;
      this.showModal = true;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .md-table-cell-container {
    white-space: nowrap;
  }
}
</style>
